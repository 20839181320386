import React from 'react'
import {Box, Container, Flex} from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const ContentColumn = ({content: {collection}, reverse}) => (
    <Container>
        <Flex
            sx={{
                flexDirection: [
                    reverse ? `column-reverse` : `column`
                ]
            }}
        >
            <Box
                sx={{
                    flexBasis: [null, null, null, `2/5`],
                    [reverse ? 'ml' : 'mr']: [null, null, null, 5],
                    position: `relative`,
                    textAlign: ['center', 'left']
                }}
            >
                <ContentText content={collection[0]?.text} ml='0'/>
            </Box>
            <Box
                sx={{
                    flexBasis: `3/5`,
                    alignSelf: `center`,
                    textAlign: [`center`, null, null, `left`]
                }}
            >
                <ContentText content={collection[1]?.text} ml='0'/>
            </Box>
        </Flex>
    </Container>
)

export default WithDefaultContent(ContentColumn)
